import React, { useState, useCallback, useEffect, useContext } from "react";
import { Link, useHistory, useRouteMatch } from "react-router-dom";
import { EventContext } from "../context/EventContext";
import { CartContext } from "../context/CartContext";
import { ADD_PRODUCT, OPEN_CART, CLOSE_CART } from "../reducers/CartReducer";
import {
  getAvailability,
  fetchAvailabilities,
  getCartProduct,
  getSizeScale,
} from "../firestore";
import {
  asyncFilter,
  getNzForSizeInSizeScale,
  getBitmap,
  getPriceString,
  getPriceMargin,
  capitalizeFirstLetter,
} from "../helpers";
import { HIDE_SINGLE_SIZE } from "../constants";
import Header from "./Header";
import Embroidery from "./Embroidery";
import { CLIENT, GTN } from "../constants";

const ProductDetail = () => {
  const [addingToCart, setAddingToCart] = useState(false);
  const [productSet, setProductSet] = useState([]);
  const [selectedColor, setSelectedColor] = useState();
  const [selectedSize, setSelectedSize] = useState();
  const [msrp, setMsrp] = useState();
  const [selectedAngle, setSelectedAngle] = useState();
  const [selectedEmbroidery, setSelectedEmbroidery] = useState([]);
  const [sizes, setSizes] = useState([]);
  const [sizeScale, setSizeScale] = useState([]);
  const [addLogo, setAddLogo] = useState(false);
  const {
    eventID,
    customUnits,
    productList,
    sortingPath,
    products,
    lastOrderDate,
    lastLogoDate,
    supportLogos,
    discountPercentage
  } = useContext(EventContext);
  const { cartState, cartDispatch } = useContext(CartContext);
  const history = useHistory();
  const match = useRouteMatch();
  const styleCode = match ? match.params.styleCode : null;
  const colorCode = match ? decodeURIComponent(match.params.colorCode) : null;
  const isClientGTN = CLIENT === GTN;

  useEffect(() => {
    document.body.className = "productdetail";
    return () => {
      document.body.className = "";
    };
  }, []);

  // Set the selected color and product set
  useEffect(() => {
    if (!styleCode || !colorCode || !products) return;
    const numProducts = Object.values(products).length;
    if (numProducts < 1) return;
    const filteredStyles = Object.values(products).filter((p) => {
      return p.styleCode === styleCode;
    });
    const matchedProduct = filteredStyles.find(
      (p) => p.colorCode === colorCode
    );
    setSelectedColor(matchedProduct ? matchedProduct : filteredStyles[0]);
    if (!matchedProduct) return;
    // Get the set of all products with this style name
    const allColors = Object.values(products).filter(
      (p) => p.styleNameLong === matchedProduct.styleNameLong
    );
    setProductSet(allColors);
  }, [products, styleCode, colorCode]);

  useEffect(() => {

    if (!selectedColor) return;
    // Set the size scale for this style
    getSizeScale(selectedColor.xID).then(async (sizes) => {
      // Get the sizes that were initially offered

      const filteredSizes = await asyncFilter(sizes, async (size) => {
        const nz = getNzForSizeInSizeScale(size, sizes);
          const availability = await getAvailability(selectedColor, nz);
          return (
            typeof availability !== "undefined" &&
            size !== "" &&
            availability >= 0
          );
        });
        // Show all sizes while availabilty is being fetched
        const sizesWithoutAvailability = filteredSizes.reduce(
        (s, v) => ({ ...s, [v]: 1 }),
        {}
      );
      
      
      setSizes(sizesWithoutAvailability);
      // Get availability of all sizes
      const availableSizes = await fetchAvailabilities(
        selectedColor,
        filteredSizes,
        sizes,
        lastOrderDate
      );
      
      // If there are no sizes listed, assume sold out
      if (Object.keys(availableSizes).length === 0) {
        setSizes(null);
      } else {
        setSizes(availableSizes);
        const sizeNames = Object.keys(availableSizes);
        const firstSize = sizeNames[0];
        const singleSize = sizeNames.length === 1;
        if (singleSize && HIDE_SINGLE_SIZE) setSelectedSize(firstSize);
      }
      setSizeScale(sizes);
    }    
      );     

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedColor]);


  // Change the default image to match the selected color and set the MSRP
  useEffect(() => {
    if (!selectedColor) return;
    setSelectedAngle(selectedColor.image);

    const wholesalePrice =
      selectedColor.wholesalePrice > 0 ? selectedColor.wholesalePrice : 0;
    const priceMargin = getPriceMargin(selectedColor);
    const factor = 1 / (1 - priceMargin);
    const msrp = wholesalePrice * factor;
    const roundedUp = Math.ceil(msrp);
    const fixedMsrp = roundedUp.toFixed(2);
    setMsrp(fixedMsrp);
  }, [selectedColor]);

  const renderNoSizes = useCallback(() => {
    return (
      <div className="nonEssentialWorker sizeWrapper">
        <div className="line" />
        <label className="sizeContainer false">
          <input type="radio" name="sizeRadio" disabled={true} />
          <span className="soldOut">{"SOLD OUT"}</span>
        </label>
      </div>
    );
  }, []);

  const renderSize = useCallback(
    (size) => {
      const availability = sizes[size];
      return (
        <div
          key={size}
          onClick={() => setSelectedSize(size)}
          className="nonEssentialWorker sizeWrapper"
        >
          <div className="line" />
          <label className="sizeContainer false">
            <input
              type="radio"
              name="sizeRadio"
              disabled={availability === 0}
            />
            <span disabled={availability === 0} className="size">
              {size === "OSFA" ? "One Size" : size}
            </span>
          </label>
        </div>
      );
    },
    [sizes]
  );

  // Show the size scale for this product style
  const renderAllSizes = useCallback(() => {
    // Sizes is null if no sizes were listed in the inventory
    if (sizes === null) {
      console.log(`No sizes listed for ${selectedColor.xID}`);
      return renderNoSizes();
    }
    // Sort sizes by their size value as floats to properly sort half sizes
    const sortedSizes = Object.keys(sizes).sort(
      (a, b) => parseFloat(a) - parseFloat(b)
    );
    return sortedSizes.map((size) => {
      return renderSize(size);
    });
  }, [selectedColor, sizes, renderNoSizes, renderSize]);

  const renderSizeSelection = useCallback(() => {
    // Don't render a single size if the feature flag is set
    if (sizes === null) {
      const singleSize = false;
      if (singleSize && HIDE_SINGLE_SIZE) return;
      return (
        <div className="configSize">
          <h2>Select Size</h2>
          <div className="sizes">{renderAllSizes()}</div>
        </div>
      );
    }
    const singleSize = Object.keys(sizes).length === 1;
    if (singleSize && HIDE_SINGLE_SIZE) return;
    return (
      <div className="configSize">
        <h2>Select Size</h2>
        <div className="sizes">{renderAllSizes()}</div>
      </div>
    );
  }, [sizes, renderAllSizes]);

  // Show the color options for this style
  const renderColorOptions = useCallback(() => {
    // No need to show options for colors if there's less than 2 colors
    if (!productSet || !styleCode || productSet.length < 2) return;
    const colorName = selectedColor.colorNameLong
      ? selectedColor.colorNameLong
      : selectedColor.colorCode;
    return (
      <div className="colorPicker">
        <div>Select Color</div>
        <div className="colorName">{colorName}</div>
        <div className="colors">
          {productSet.map((product, i) => {
            const encodedStyle = encodeURIComponent(product.styleCode);
            const encodedColor = encodeURIComponent(product.colorCode);
            const thisColor = selectedColor === product;
            return (
              <Link
                to={`/${eventID}/store/product/${encodedStyle}/${encodedColor}`}
                // onClick={() => setSelectedColor(product)}
                key={i}
                className={`nonEssentialWorker color${i + 1} ${
                  thisColor ? "highlighted" : ""
                }`}
              >
                <label>
                  <input type="radio" name="colorRadio" />
                  <span
                    className={`color ${thisColor ? "highlighted" : ""}`}
                    style={{
                      backgroundImage: `url(${getBitmap(product.image)})`,
                    }}
                  ></span>
                </label>
              </Link>
            );
          })}
        </div>
      </div>
    );
  }, [eventID, productSet, selectedColor, styleCode]);

  const renderProductFeatures = (details) => {
    if (!details) return;
    const colorText = details.split("<br>")[0];
    const endIndex = details.indexOf("</b>");
    const remainingText = details.substring(endIndex + 4).trim();
    // Split the remaining text into bullet points separated by <br> tags
    const bulletPoints = remainingText.split("<br>").map((point, index) => {
      const trimmedPoint = point.trim();
      return trimmedPoint ? <li key={index}>{trimmedPoint}</li> : null;
    });
    return (
      <div>
        {colorText}
        <h3>Product Features</h3>
        <ul>{bulletPoints}</ul>
      </div>
    );
  };

  // Render the large main image
  const renderMainImage = useCallback(() => {
    if (!selectedAngle) return;
    return (
      <div
        className="productImage"
        style={{ backgroundImage: `url(${getBitmap(selectedAngle)})` }}
      >
        <img
          alt=""
          style={{
            opacity: "0",
            width: "100%",
            height: "100%",
            position: "absolute",
          }}
          className="zoomContainer"
          src={getBitmap(selectedAngle)}
        />
      </div>
    );
  }, [selectedAngle]);

  // Show the different view angles for this product
  const renderViews = useCallback(() => {
    // No need to show options for multiple angles if there's less than 2 angles
    if (!selectedColor || selectedColor.imageAngles.length < 2) return;
    const views = selectedColor.imageAngles;
    return (
      <div className="views">
        {views.map((view, i) => {
          return (
            <div
              onClick={() => setSelectedAngle(view)}
              key={i}
              className="viewWrapper"
            >
              <img
                alt={`angle ${i + 1}`}
                style={{ cursor: "pointer" }}
                src={getBitmap(view)}
              />
            </div>
          );
        })}
      </div>
    );
  }, [selectedColor, setSelectedAngle]);

  // Checks for adding to cart
  const validate = useCallback(async () => {
    if (!selectedSize) {
      throw new Error("Please select a size before adding to your cart");
    }
    const invalidPriceFromEventManager = !selectedColor.price;
    if (invalidPriceFromEventManager) {
      throw new Error(
        "Sorry, the price for this product was not properly set. Please contact the event manager,"
      );
    }
    // Find this product in our cart
    const nz = getNzForSizeInSizeScale(selectedSize, sizeScale);
    const availability = await getAvailability(
      selectedColor,
      nz,
      lastOrderDate
    );
    const thisProductInCart = Object.values(cartState.products).find(
      (p) => p.product === selectedColor.xID && p.size === selectedSize
    );
    const soldOut = availability <= 0;
    const tooManyInCart =
      thisProductInCart && availability <= thisProductInCart.quantity;
    if (soldOut) {
      throw new Error("Sorry, the selected size is no longer available.");
    }
    if (tooManyInCart) {
      throw new Error("Sorry, there are no more of this item in this size.");
    }
  }, [selectedSize, selectedColor, sizeScale, cartState, lastOrderDate]);

  // Adds the product to the cart
  const addToCart = useCallback(
    async (addLogo) => {
      setAddingToCart(true);
      try {
        await validate();
      } catch (e) {
        alert(e.message);
        setAddingToCart(false);
        return;
      }
      const embroidery = selectedEmbroidery;
      const { id, quantity } = await getCartProduct(
        selectedColor,
        selectedSize,
        embroidery,
        addLogo,
        cartState.cartID
      );
      const cartProduct = {
        id,
        quantity,
        product: selectedColor,
        size: selectedSize,
        embroidery,
        addLogo: addLogo,
      };
      cartDispatch({
        type: ADD_PRODUCT,
        productList,
        cartProduct: cartProduct,
      });
      cartDispatch({ type: OPEN_CART, cartProduct: cartProduct });
      // Close after 2 seconds
      setTimeout(() => {
        cartDispatch({ type: CLOSE_CART });
      }, 5000);
      setAddingToCart(false);
    },
    [
      validate,
      selectedEmbroidery,
      selectedColor,
      selectedSize,
      setAddingToCart,
      cartDispatch,
      cartState,
      productList,
    ]
  );

  const handleAddLogoChange = (e) => {
    setAddLogo(e.target.checked);
  };

  // Go back to the shopping page with the last sorting options
  const backToShopping = useCallback(() => {
    history.push(sortingPath ? sortingPath : `/${eventID}/store`);
  }, [history, sortingPath, eventID]);

  if (!selectedColor) return null;

  const css = `
.views:after {
content:url(${selectedColor.image});
position:absolute; width:0; height:0; overflow:hidden; z-index:-1;
}
`;

  const lastPrice =
    selectedColor.price -
    selectedColor.price * (parseFloat(discountPercentage) / 100);

  return (
    <React.Fragment>
      <Header addLogo={addLogo} />
      <div className="productPane">
        <label onClick={() => backToShopping()} className="backLink">
          <span className="iconArrow"></span>Back to Shopping
        </label>{" "}
        <div className="productDetailPane">
          <div className="productDetailGrid">
            <div className="nonEssentialWorker">
              {renderMainImage()}
              {renderViews()}
            </div>

            <style>{css}</style>

            <div className="productDetailPane">
              <div className="productInfo">
                <span className="catName">{selectedColor.category}</span>
                {selectedColor.price < msrp && !customUnits && (
                  <span className="productPrice">
                    <del>${msrp}</del>
                  </span>
                )}
                <div />
                {discountPercentage && discountPercentage > 0 ? (
                  <div>
                    <span className="productPrice discountedPrice">
                      {getPriceString(lastPrice)}
                    </span>
                    <span
                      style={{ textDecoration: "line-through" }}
                      className="productPrice"
                    >
                      {getPriceString(
                        selectedColor.price,
                        customUnits,
                        addLogo
                      )}
                    </span>
                    <span className="productName greyBorder">
                      {capitalizeFirstLetter(selectedColor.styleNameLong)}{" "}
                      {selectedColor.dimensionCode}
                    </span>
                  </div>
                ) : (
                  <span className="productPrice">
                    {getPriceString(selectedColor.price, customUnits, addLogo)}
                  </span>
                )}
              </div>

              <div className="productConfig">
                <div className="desc-color">{renderColorOptions()}</div>

                <div className="size-logo">
                  {renderSizeSelection()}
                  {selectedColor.embroidery &&
                  lastLogoDate?.toDate() > new Date() ? (
                    <>
                      <h2>Select Embroidery</h2>
                      <Embroidery
                        options={selectedColor.embroidery}
                        setSelectedEmbroidery={setSelectedEmbroidery}
                      />
                    </>
                  ) : null}

                  {isClientGTN && selectedColor.canEmbroider && supportLogos ? (
                    <div>
                      <label>
                        <input
                          type="checkbox"
                          checked={addLogo}
                          onChange={handleAddLogoChange}
                        />
                        Would you like to add a logo?{" "}
                        {customUnits !== "Units" && "(+$5)"}
                      </label>
                    </div>
                  ) : null}

                  <button
                    onClick={() => addToCart(addLogo)}
                    className="button buyButton"
                    disabled={addingToCart}
                  >
                    {addingToCart ? "Adding To Cart..." : "Add To Cart"}
                  </button>
                  {isClientGTN && (
                    <>
                      <br></br>
                      <br></br>
                      {renderProductFeatures(selectedColor.details)}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ProductDetail;
