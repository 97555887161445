import React, { useState, useContext, useCallback, useMemo } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { CartContext } from "../context/CartContext";
import { ADD_PAYPAL } from "../reducers/CartReducer";
import { EventContext } from "../context/EventContext";
import { getStripeKeys, stripePayment } from "../firestore";

const PaymentForm = () => {
  // eslint-disable-next-line no-unused-vars
  const { cartState, cartDispatch, dropShip } = useContext(CartContext);
  const { customerID } = useContext(EventContext);
  const stripe = useStripe();
  const elements = useElements();
  const [paymentFlag, setPaymentCall] = useState(false);

  const handleSubmit = useCallback(
    async (event) => {
      event.preventDefault();
      try {
        const amountPaid = cartState.balance * -1;

        if (amountPaid <= 0)
          throw new Error("No need to pay if the balance is positive");
        else if (amountPaid <= 0.5)
          throw new Error("Stripe minimum is $0.50 cent");
        event.preventDefault();

        /*need a paymentFlag to deal wit multiple clicks on the pay button*/
        if (!paymentFlag) {
          setPaymentCall(true);

          const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: "card",
            card: elements.getElement(CardElement),
          });

          if (error) {
            setPaymentCall(false);
            throw error;
          }

          const amount = Math.floor(amountPaid * 100);

          const response = await stripePayment({
            id: paymentMethod.id,
            customerID,
            amount: amount,
          });
          const result = response.data;

          if (result.success) {
            console.log(`Paid ${amountPaid} with Stripe`);
            cartDispatch({ type: ADD_PAYPAL, amount: amountPaid });
          } else {
            throw new Error(result.message);
          }
          setPaymentCall(false);
        }
      } catch (err) {
        console.error("Error with payment:", err);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      cartState.balance,
      cartDispatch,
      elements,
      stripe,
      setPaymentCall,
      paymentFlag,
    ]
  );

  return (
    <form style={{ width: "100%", padding: "1px" }} onSubmit={handleSubmit}>
      <CardElement />
      <br />
      <button className="button" type="submit" disabled={!stripe}>
        Pay
      </button>
    </form>
  );
};

const Stripe = () => {
  const { customerID } = useContext(EventContext);
  const [stripePromise, setStripePromise] = useState();

  const pk = useMemo(async () => {
    if (!customerID) return;
    const result = await getStripeKeys({ customerID });
    const pk = result.data?.pk;
    setStripePromise(loadStripe(`${pk}`));
    return pk;
  }, [customerID]);

  if (!customerID || !pk || !stripePromise) return null;

  return (
    <Elements stripe={stripePromise}>
      <PaymentForm />
    </Elements>
  );
};

export default Stripe;
